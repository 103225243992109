




















































import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";
import { Notification, NotificationType } from "@/core/models";

@Component
export default class Notifications extends Vue {
  @Getter("profile/isAuthenticated") isAuth!: boolean;
  @Getter("notifications/getNotifications") notifications!: Notification[];
  @Getter("notifications/retrieved") retrieved!: boolean;
  @Getter("notifications/newNotificationsCount") newNotificationsCount!: number;
  @Getter("notifications/lastChecked") lastChecked!: Date;
  @Action("notifications/loadNotifications") loadNotifications!: () => void;
  @Action("notifications/checkNotifications") checkNotifications!: () => void;

  shown = false;
  @Watch("shown")
  menuChanged() {
    if (!this.shown) this.checkNotifications();
  }

  isNew(notification: Notification): boolean {
    const lastCheckedDate = new Date(this.lastChecked);

    if (!isNaN(lastCheckedDate.getTime()))
      return lastCheckedDate.getTime() < notification.date.getTime();
    else {
      console.error("Invalid lastChecked value:", this.lastChecked);
      return false;
    }
  }

  leave(el: any, done: Function) {
    console.log(el);
    console.log("leave");
    this.checkNotifications();
    done();
  }

  getIcon(type: NotificationType): string {
    switch (type) {
      case NotificationType.FeedbackReceived:
        return "mdi-video-account";
      case NotificationType.RateVideo:
        return "mdi-video";
      case NotificationType.RatingReceived:
        return "mdi-video-plus";
      default:
        return "mdi-video";
    }
  }

  getNotificationTitle(type: NotificationType): string {
    switch (type) {
      case NotificationType.FeedbackReceived:
        return this.$t("notifications.feedback_received").toString();
      case NotificationType.RateVideo:
        return this.$t("notifications.rate_video").toString();
      case NotificationType.RatingReceived:
        return this.$t("notifications.rating_received").toString();
      default:
        return "NOTIFICATION TYPE NOT SUPPORTED";
    }
  }

  getNotificationsTimeSpan(date: Date): string {
    const now = new Date().getTime();
    var diff = now - date.getTime();
    var minutes = Math.floor(diff / 1000 / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var weeks = Math.floor(days / 7);
    var months = Math.floor(weeks / 30);

    if (months > 0) return months + " " + this.$t("notifications.months");
    if (weeks > 0) return weeks + " " + this.$t("notifications.weeks");
    if (days > 0) return days + " " + this.$t("notifications.days");
    if (hours > 0) return hours + " " + this.$t("notifications.hours");
    if (minutes > 0) return minutes + " " + this.$t("notifications.minutes");
    return this.$t("notifications.fewSeconds").toString();
  }

  @Watch("isAuth", { immediate: true })
  isAuthChanged() {
    if (!this.isAuth) return;
    if (!this.retrieved) this.loadNotifications();
  }
}
